<template>
  <div class="modal-container">
    <div class="paymentModal">
      <div class="paymentModal-inner">
        <div class="div-pagar">
          <div class="paymentModal-close" @click="closePaymentModal()">
            &times;
          </div>
          <StepProcess
            :steps="paymentSteps"
            :currentStep="currentStep"
            :completionData="completionData"
            @stepChange="stepChange"
          />
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <UserData :userData="userData" v-if="currentStep===1" :productInfo="modalInfo" @userDataChange="userDataChange"/>
                <RegisterDetails :regForm="regForm" v-if="currentStep===2" :productInfo="modalInfo" @regFormChange="regFormChange" />
                
                <PaymentMethods v-if="currentStep===3" @paymentChange="paymentChange"/>
              </div>
              <div class="col-md-6">
                <SummaryAndContinue :regForm="regForm" :userData="userData" :products="[modalInfo]" :currentStep="currentStep" :paymentMethod="paymentMethod" 
                @nextStep="stepChange"
                :completionData="completionData"
                @regFormCompletion="handleCompletion($event,'regFormCompletion')"
                @userDataCompletion="handleCompletion($event,'userDataCompletion')"
                @registrationSuccess="handleCompletion($event,'registrationSuccess')"
                @closePaymentModal="closePaymentModal"
                
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepProcess from "./StepProcess.vue";
import RegisterDetails from "./RegisterDetails.vue";
import UserData from "./UserData.vue";
import PaymentMethods from "./PaymentMethods.vue";
import SummaryAndContinue from "./SummaryAndContinue.vue";
import { usePaymentModal } from "../../../store/paymentModal";
import { useShoppingCart } from "../../../store/shoppingCart";

export default {
  name: "PaymentModal",
  components: {
    StepProcess,
    RegisterDetails,
    UserData,
    PaymentMethods,
    SummaryAndContinue,
  },
  data() {
    return {
      currentStep: 1,
      paymentMethod:{
        transfer:false,
        culqi:false
      },
      paymentSteps: [
        "Detalles de la Compra",
        "Opciones de Pago",
        "Resumen y Continuación",
      ],
      regForm:{
        email: "",
      password: "",
      confirmPassword: "",
      },
      userData:{
        fullname: "",
        phone: "",
        documentType:"",
        documentNumber: "",
      },
      completionData:{
        regFormCompletion:false,
        userDataCompletion:false,
        registrationSuccess:false
      }
    };
  },
  computed: {
    modalInfo() {
      return usePaymentModal().$state.paymentModalInfo;
    },
    cartProducts(){
            return useShoppingCart().$state.cartProducts
        }
  },
  methods: {
    closePaymentModal() {
      this.$emit("closePaymentModal");
    },
    stepChange(stepNumber) {
      this.currentStep = stepNumber;
    },
    paymentChange(paymentMethod){
      this.paymentMethod=paymentMethod
    },
    regFormChange(regForm){
      this.regForm=regForm;
    },
    userDataChange(userData){
      this.userData=userData;
    },
    handleCompletion(data, event) {
      this.completionData[event] = data;
    },
    /*     paymentSelected(paymentMethod) { */
    // Handle payment method selection
    /*   }, */
    async pay() {
      // Handle payment process
    },
  },
  watch:{
    cartProducts(newVal,oldVal){
      console.log(oldVal)
      console.log(newVal)
    }
  },
  mounted(){
   
  }
};
</script>

<style scoped>
.paymentModal-inner{
  width: 70%;
}
</style>
