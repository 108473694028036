<template>
  <div class="step-process">

    <div class="step-container" v-for="step in steps" :key="step.id">
      <div class="step-line" v-if="step.id <= currentStep && step.id != 1">
      </div>
      <div class="step" @click="navigateToStep(step.id)">
        <div class="step-number" :class="{ active: step.id < currentStep }">
          {{ step.number }}
        </div>
        <div class="step-title">{{ step.title }}</div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "StepProcess",
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    completionData:{
      type:Object
    }
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          number: 1,
          title: "Datos",
        },
        {
          id: 2,
          number: 2,
          title: "Registro",
        },
        {
          id: 3,
          number: 3,
          title: "Pago",
        },
      ],
    };
  },
  methods: {
    navigateToStep(stepId) {
      if(this.currentStep===1){
        if(stepId===2){
          if(this.completionData.userDataCompletion){
             this.$emit('stepChange', stepId)
          }else{
            this.$toast.warning('Complete los campos adecuadamente')
          }
        }else if(stepId===3){
          this.$toast.warning('Complete el paso 2')
        }
      }else if(this.currentStep===2){
        if(stepId===3){
          if(this.completionData.registrationSuccess){
             this.$emit('stepChange', stepId)
          }else{
            this.$toast.warning('Complete los campos o inténtelo de nuevo')
          }
        }else if(stepId<=this.currentStep){
          this.$emit('stepChange', stepId)
        }
      }else if(this.currentStep===3 && stepId<this.currentStep){
        this.$toast.warning('Usted ya completó su registro')
      }
     
    }
  }
};
</script>

<style scoped>
.step-process {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-container {
  width:25%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.step {
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: #fff;
  transition: all 0.3s ease;
}

.step.active {
  background: #007bff;
  color: #fff;
}

.step-number {
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.step-title {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 700;
}

/* revisar esto aun no funciona */
.step-line {
  position: absolute;
  width: calc(100% - 50px);
  height: 2px;
  background-color: #d3d0d0;
  top:50%;
  right:calc(((100% - 50px) / 2) + 50px);

  z-index: 1;
}
</style>
