<template>
  <div>
    <div class="text-start py-5">
      <h1>{{ title }}</h1>
    </div>
    <h2>Cursos</h2>
    <!-- <v-sheet class="mx-auto" color="#f5f3f3">

      <v-slide-group
        style="min-height: 280px"
        v-model="model"
        center-active
        show-arrows
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
      >
        <v-slide-item v-for="course in products" :key="course.id" class="px-3">
          <ProductCard :course="course" :mode="mode" />
        </v-slide-item>
      </v-slide-group>
    </v-sheet> -->
    <CartPayModal v-if="isCartPayModalOpen" @closeCartPay="togglePaymentModal()"></CartPayModal>
    <PaymentModal v-if="isPaymentModalOpen" @closePaymentModal="togglePaymentModal()"></PaymentModal>
    <ProductModal v-if="isProductModalOpen" :curso="curso" @closeProductInfo="toggleProductModal()" @openPaymentModal="togglePaymentModal()" :course="toggleProductModal"></ProductModal>
    
    <carousel
      :autoplay="true"
      :loop="true"
      :autoplayLoop="true"
      :autoplayTimeout="3000"
      :autoplayHoverPause="true"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :perPageCustom="[
        [0, 1],
        [450, 1],
        [680, 2],
        [940, 2],
        [1100, 3],
        [1200, 3],
      ]"
    >
      <slide v-for="course in products" :key="course.id">
        <ProductCard :course="course" :mode="mode" @openProductInfo="toggleProductModal()" @openPaymentModal="togglePaymentModal()"/>
      </slide>
    </carousel>
    <h2>Diplomados</h2>
    <carousel
      :autoplay="true"
      :loop="true"
      :autoplayLoop="true"
      :autoplayTimeout="3000"
      :autoplayHoverPause="true"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :perPageCustom="[
        [0, 1],
        [450, 1],
        [680, 2],
        [940, 2],
        [1100, 3],
        [1200, 3],
      ]"
    >
      <slide v-for="course in products" :key="course.id"  >
        <ProductCard :course="course" :mode="mode" @openProductInfo="toggleProductModal()" @openPaymentModal="togglePaymentModal"/>
      </slide>
    </carousel>
  </div>
</template>

<script>
//import eventBus from "../../../main";
import CartPayModal from "@/components/public/CartPayModal/index.vue"
import PaymentModal from "@/components/public/PaymentModal/index.vue";
import ProductModal from "@/components/public/ProductModal/index.vue";
import ProductCard from "@/components/public/ProductCard/index.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "ProductsSlider",
  components: {
    CartPayModal,
    PaymentModal,
    ProductModal,
    ProductCard,
    Carousel,
    Slide,
  },
  data() {
    return {
      mode: null,
      model: null,
      isProductModalOpen: false,
      isPaymentModalOpen: false,
      isCartPayModalOpen: false
      // showPrivateNavbar: null,
    };
  },
  mounted() {
    // this.showPrivateNavbar = localStorage.getItem("showPrivateNavbar");
    this.mode = localStorage.getItem("mode");
    // alert(this.showPrivateNavbar);
  },
  props: {
    products: {
      type: Array,
    },
    title: {
      type: String,
    },
    width: Number,
    height: {
        type: String,
        default: "100%",
    },
  },
  methods: {
    toggleProductModal() {
      this.isProductModalOpen = !this.isProductModalOpen
    },
    togglePaymentModal() {
     if(localStorage.getItem("access_token") && !this.isPaymentModalOpen){
      this.isCartPayModalOpen=!this.isCartPayModalOpen
     }else{
      this.isPaymentModalOpen = !this.isPaymentModalOpen
     }
      
    },
  },
};
</script>

<style scoped>
.icon-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Para que el icono esté encima de la imagen */
}

.course-card {
  position: relative; /* Necesario para que el icon-container esté posicionado relativo a este contenedor */
  
}
.course-container {
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  transition: 0.5s;
  max-width:315px;
  min-width: 265px;
  font-family:Segoe UI ;
}

.course-card:hover .img-card {
  filter: brightness(0.3);
  transition: 0.5s;
  cursor: pointer;
}


.text-color {
  background-color: #131b1e;
}




.extra-info-container {
  display: flex;
  flex-direction: row;
  border-radius: 0px 0px 20px 20px;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0px;
  height: auto;
  padding: 20px;
}
.row-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px;
}

.row-info button {
  font-size: 14px;
  font-weight: 700;
  padding: 5px 5px;
  border-radius: 8px;
}
.curso {
  font-size: 16px;
  font-weight: 600;
}
.first-row {
  font-size: 1.2em;
  /* text-decoration-line: line-through; */
}
.mdi:before,
.mdi-set {
  color: #ffc107;
}
.productModal{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: grid;
        place-items: center;
    }
    .productModal-close{
        position: absolute;
        height: 50px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        font-size: 3rem;
        color: #ffc107;
        cursor: pointer;
    }
    .productModal-inner{
        background-color: #fff;
        color: #000;
        position: relative;
        width: 50%; 
        border-radius: 15px;
    }
    .productModal-inner h3{
        margin: 2.5%;
    }
    .productModal-inner p{
        margin: 2.5%;
    }
    .productModal-inner img{
        width: 100%;
        aspect-ratio: 16/9;
    }
    .productModal-extra{
        margin: 2.5%;
    }
    #productModal-btn{
        border-radius: 15px;
        padding: 10px 50px;
        display: flex;
        float: right;
        margin: 0px 20px 20px 0px;
    }
    #goToCourse-icon{
        cursor: pointer;
    }
</style>
