<template>
  <div
    :title="course.title"
    class="course-container py-5"
    v-if="course"
    style="margin: auto !important"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div class="course-card" v-bind="attrs" v-on="on">
          <img
            loading="lazy"
            :src="course.portada_url"
            alt="no image"
            class="img-fluid img-card"
            style="border-radius: 20px 20px 0px 0px"
          />
          <div class="icon-container">
            <img
              src="../../../assets/play.svg"
              alt="play"
              class="play-button"
            />
            <div class="hover-content" @click="openProductInfo()">
              <img src="../../../assets/view.png" alt="vista" />
              <p>Ver trailer</p>
            </div>
          </div>
          <div class="extra-info-container text-color py-3">
            <div
              v-if="course.precio > 0"
              class="first-row text-white text-center"
            >
              <div class="row-info">
                <div style="height: 48px; overflow: hidden">
                  <span class="text-left curso">{{ course.nombre }}</span>
                </div>
                <span class="text-left text-secondary"
                  ><img
                    src="../../../assets/minicertificate.svg"
                    alt=""
                  />Incluye certificado
                </span>
                <span class="text-left text-secondary"
                  ><img src="../../../assets/minicertificate.svg" alt="" />100%
                  virtual</span
                >

                S/. {{ course.precio.toFixed(2) }}
                <p></p>
              </div>
              <div class="row justify-content-center mt-1">
                <div class="col-3">
                  <button>
                    <img
                      class="border border-warning px-3 rounded-lg"
                      src="../../../assets/wtsp.svg"
                      alt=""
                      style="padding: 5px"
                    />
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-warning px-4 rounded-lg"
                    @click="openPaymentModal()"
                  >
                    Comprar
                  </button>
                </div>
                <div class="col-3">
                  <button>
                    <img
                      class="border border-warning px-3 rounded-lg cartProductBtn"
                      src="../../../assets/shoppingcar.svg"
                      @click="addCartItem()"
                      alt=""
                      style="padding: 5px"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </template>
      <span>{{ course.nombre }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { useProductInfo } from "../../../store/productModal";
import { usePaymentModal } from "../../../store/paymentModal";
import { useShoppingCart } from "../../../store/shoppingCart";

export default {
  name: "ProductCard",
  data() {
    return {};
  },
  props: {
    course: {
      type: Object,
    },
    mode: {
      type: String,
      default: "private",
    },
    // showPrivateNavbar: {
    //   type: Boolean,
    //   default: false,
    // },
    width: Number,
    height: {
      type: String,
      default: "100%",
    },
  },
  methods: {
    getProductType(id) {
      if (id == 1) {
        return "curso";
      } else if (id == 2) {
        return "diplomado";
      }
    },
    openProductInfo() {
      const newProduct = this.course;
      useProductInfo().updateModalInfo(newProduct);
      this.$emit("openProductInfo");
    },
    openPaymentModal() {
      const newProduct = this.course;
      usePaymentModal().updateModalInfo(newProduct);
      this.$emit("openPaymentModal");
    },
    addCartItem() {
      const newProduct = this.course;
      useShoppingCart().addCartItem(newProduct);
      console.log(useShoppingCart().$state.cartProducts);
    },
  },
  components: {},
};
</script>

<style scoped>
.course-card:hover .play-button {
  display: none;
}

.course-card:hover .hover-content {
  width: 200px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
.course-card:hover .hover-content img {
  width: 20%;
  margin-right: 10px;
}
.course-card:hover .hover-content p {
  padding: 0px;
  margin: 0px;
}
.hover-content {
  display: none;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cartProductBtn:hover {
  fill: white;
}

.icon-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-130%, -50%);
  z-index: 1;
}

.icon-container .play-button {
  width: 300%;
  cursor: pointer;
}

.course-card {
  position: relative;
}
.course-container {
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  transition: 0.5s;
  max-width: 315px;
  min-width: 265px;
  font-family: Segoe UI;
}

.course-card:hover .img-card {
  filter: brightness(0.3);
  transition: 0.5s;
  cursor: pointer;
}
.course-card:hover .icon-container {
  color: white;
  transition-duration: 100ms;
  cursor: pointer;
}
.couse-card:hover .hover-content {
  cursor: pointer;
  display: active;
}
.course-card:hover .play-button {
  filter: invert(100%);
  display: none;
}
.text-color {
  background-color: #131b1e;
}

.extra-info-container {
  display: flex;
  flex-direction: row;
  border-radius: 0px 0px 20px 20px;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0px;
  height: auto;
  padding: 20px;
}
.row-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px;
}

.row-info button {
  font-size: 14px;
  font-weight: 700;
  padding: 5px 5px;
  border-radius: 8px;
}
.curso {
  font-size: 16px;
  font-weight: 600;
}
.first-row {
  font-size: 1.2em;
  /* text-decoration-line: line-through; */
}
</style>
