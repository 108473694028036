import { createPinia, defineStore } from "pinia";

const pinia = createPinia()
export const usePaymentModal = defineStore('paymentModal',{
    
    id: '',
    state: () => ({
        paymentModalInfo: {}
    }),
    getters: {

    },
    actions: {
        async updateModalInfo(newProduct){
            this.paymentModalInfo = newProduct
        }
    }
})
export default pinia