import { createPinia, defineStore } from "pinia";

const pinia = createPinia()
export const useProductInfo = defineStore('productInfo',{
    
    id: '',
    state: () => ({
        productModalInfo: {}
    }),
    getters: {

    },
    actions: {
        async updateModalInfo(newProduct){
            this.productModalInfo = newProduct
        }
    }
})
export default pinia