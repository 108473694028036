var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-start py-5"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_c('h2',[_vm._v("Cursos")]),(_vm.isCartPayModalOpen)?_c('CartPayModal',{on:{"closeCartPay":function($event){return _vm.togglePaymentModal()}}}):_vm._e(),(_vm.isPaymentModalOpen)?_c('PaymentModal',{on:{"closePaymentModal":function($event){return _vm.togglePaymentModal()}}}):_vm._e(),(_vm.isProductModalOpen)?_c('ProductModal',{attrs:{"curso":_vm.curso,"course":_vm.toggleProductModal},on:{"closeProductInfo":function($event){return _vm.toggleProductModal()},"openPaymentModal":function($event){return _vm.togglePaymentModal()}}}):_vm._e(),_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplayLoop":true,"autoplayTimeout":3000,"autoplayHoverPause":true,"navigationEnabled":true,"paginationEnabled":false,"perPageCustom":[
      [0, 1],
      [450, 1],
      [680, 2],
      [940, 2],
      [1100, 3],
      [1200, 3],
    ]}},_vm._l((_vm.products),function(course){return _c('slide',{key:course.id},[_c('ProductCard',{attrs:{"course":course,"mode":_vm.mode},on:{"openProductInfo":function($event){return _vm.toggleProductModal()},"openPaymentModal":function($event){return _vm.togglePaymentModal()}}})],1)}),1),_c('h2',[_vm._v("Diplomados")]),_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplayLoop":true,"autoplayTimeout":3000,"autoplayHoverPause":true,"navigationEnabled":true,"paginationEnabled":false,"perPageCustom":[
      [0, 1],
      [450, 1],
      [680, 2],
      [940, 2],
      [1100, 3],
      [1200, 3],
    ]}},_vm._l((_vm.products),function(course){return _c('slide',{key:course.id},[_c('ProductCard',{attrs:{"course":course,"mode":_vm.mode},on:{"openProductInfo":function($event){return _vm.toggleProductModal()},"openPaymentModal":_vm.togglePaymentModal}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }