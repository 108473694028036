<template>
    <div class="purchase-details">
      <form class="registration-form">
        <label for="cart-user-fullname">Nombre completo:</label>
        <div class="input-group">
          <img src="@/assets/user-outline-icon.svg" alt="Nombre completo" />
          <input id="cart-user-fullname" type="text" v-model="userDataForm.fullname" />
        </div>
        <label for="cart-user-phone">Teléfono:</label>
        <div class="input-group">
          <img src="@/assets/phone-outline-icon.svg" alt="telefono" />
          <input id="cart-user-phone" type="number" v-model="userDataForm.phone" />
        </div>

        <label for="cart-document-type">Tipo de documento:</label>
        <div class="input-group">
            <div class="select-container">
                        <select id="cart-document-type" v-model="userDataForm.documentType" >
                            <option value="" disabled>Seleccione tipo de documento</option>
                            <option v-for="item in availableDocumentType" :value="item.id" :key="item.id">{{ item.name }}</option>
                            
                        </select>
                        <div class="arrow-container">
                            <img :src="require('@/assets/select-down-arrow.svg')" alt="downwards arrow">
                        </div>
                    </div>
        </div>
        <label for="cart-document-number">Número de documento:</label>
        <div class="input-group">
          <input id="cart-document-number" type="number" v-model="userDataForm.documentNumber" />
        </div>
        <!--   <button type="submit" class="btn-primary">Continuar</button> -->
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserData",
    props: {
      userData:Object
    },
    data() {
      return {
        userDataForm:this.userData,
        availableDocumentType:""
      };
    },
    methods: {
        async listDocumentType() {
            try {
                const {
                    status,
                    data: response
                } = await this.$axios.get(
                    "/public/listDocumentType"
                );
                if (status === 200) {
                    this.availableDocumentType = response;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
  watch:{
    userDataForm:{
      handler(newVal){
      this.$emit('userDataChange',newVal);
    },
    deep:true
    }
  },
  created(){
    this.listDocumentType()
  }
  };
  </script>
  
  <style scoped>
  .purchase-details {
    padding: 20px;
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 80%;
  }
  
  .input-group img {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  #cart-document-number{
    padding-left: 10px;
  }

  .select-container {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    overflow: hidden;
}

.select-container select {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    cursor: pointer;
}

.arrow-container {
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;

}
.arrow-container img{
    width: 16px;
}

  .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    width: 80%;
  }
  </style>
  