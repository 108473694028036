<template>
  <div>
    <div class="summary-container" v-if="!showTransfer">
      <p>Resumen de la compra</p>
      <div class="product-cart-container">
        <div class="product-item" v-for="product in products" :key="product.id">
          <img :src="product.portada_url" alt="Imagen del producto" />
          <div class="product-details">
            <p>{{ product.nombre }}</p>
            <p class="price">S/. {{ product.precio }}</p>
          </div>
        </div>
      </div>

      <hr />
      <div class="subtotal">
        <p>Subtotal:</p>
        <p class="price">S/. {{ subtotal }}</p>
      </div>
      <hr />
      <div class="total">
        <p>Total:</p>
        <p class="price">S/. {{ total }}</p>
      </div>


    </div>
    <div class="d-flex" style="column-gap: 20px;" v-if="!showTransfer">
      <button @click="backStep" v-if="currentStep>1" >Regresar</button>
    <button @click="nextStep">Continuar</button>
    </div>
    
    
    <div v-if="showTransfer">

      <v-form ref="form" v-model="valid" lazy-validation class="px-5">
        <v-select light outlined item-text="name" v-model="bankModel" item-value="value" ref="bank" :items="banks"
          :rules="[(v) => !!v || 'Seleccione una entidad bancaria']" label="Entidad bancaria" required></v-select>
        <v-text-field light :rules="operationNumber" v-model="operationModel" label="Número de operación" required
          outlined></v-text-field>
          <div class="total">
        <p>Total:</p>
        <p class="price">S/. {{ total }}</p>
      </div>
        <div class="text-danger" v-if="repeated">
          El número de operación ya fue utilizado
        </div>
        <div class="text-center">
          <v-btn color="#ffc107" class="mr-4 font-weight-bold" @click="showTransfer = false">
            Regresar
          </v-btn>
          <v-btn :disabled="valid == false" color="#ffc107" class="mr-4 font-weight-bold" @click="validate">
            Enviar
          </v-btn>
        </div>
      </v-form>

    </div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Número de operación enviado
        </v-card-title>
        <v-card-text class="pt-5 text-center">
          En breve validaremos el número de operación y le habilitaremos el acceso al curso
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="transferSuccessDialog">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalErrorPay" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Error
        </v-card-title>
        <v-card-text class="pt-5 text-center">
          {{ errorMessage }}
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="modalErrorPay = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CulqiCheckout from "vue-culqi-checkout";
export default {
  name: "SummaryAndContinue",
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    products: {
      type: Array
    },
    paymentMethod: {
      type: Object
    },
    regForm: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true
    },
    completionData: {
      type: Object
    }
  },
  data() {
    return {
      dialog: false,
      showTransfer: false,
      valid: true,
      overlay: false,
      modalErrorPay: false,
      errorMessage: "El pago no pudo realizarse, por favor consulte el problema con la entidad bancaria",
      bankModel: "",
      banks: [{
        name: 'Scotiabank',
        value: 'Scotiabank'
      },
      {
        name: 'BCP',
        value: 'BCP'
      },
      {
        name: 'BBVA',
        value: 'BBVA'
      },
      {
        name: 'Bco. de la Nación',
        value: 'Nación'
      }
      ],
      operationModel: "",
      operationNumber: [
        (v) => !!v || "Nro. de operación requerida",
        // (v) =>
        //   (v && v.length >= 8) ||
        //   "El documento debe tener al menos 8 caracteres",
      ],
      repeated: false,
      culqiConfig: {
        //publicKey: "pk_test_cdbcffcc00366c42",
        publicKey: "pk_live_95f8434020538bb8",
        title: "INSTITUTO PERUANO SSOMA",
        currency: "PEN",
        style: {
          logo: "https://www.ipssoma.com/images/s2.png",
          maincolor: "#ffbf00",
          buttontext: "#ffffff",
        },
      },

    };
  },
  computed: {
    subtotal() {
      return this.products.reduce((total, product) => total + product.precio, 0);
    },
    total() {
      return this.subtotal + 0; // Agregar costo de envío o cualquier otro costo adicional
    },
  },
  watch: {
    paymentMethod(newVal) {
      if (newVal.culqi) {
        this.showTransfer = false;
      }
    }
  },
  methods: {
    backStep(){
      if(this.currentStep>1){
        if(this.currentStep===3){
          this.$toast.warning('Usted ya completó su registro')
          return
        }
        this.$emit('nextStep', this.currentStep - 1)
      }
      
    },
    async nextStep() {
      
      if (this.currentStep === 1) {
        if (this.userData.fullname && this.userData.phone && this.userData.documentType && this.userData.documentNumber) {
          this.$emit('userDataCompletion', true)
          this.$emit('nextStep', this.currentStep + 1)
        } else {
          this.$emit('userDataCompletion', false)
          this.$toast.warning('Complete los campos antes de continuar')
        }
        
      } else if (this.currentStep === 2) {
        //validacion email y contrasena
        if (this.regForm.email && this.regForm.password && this.regForm.confirmPassword) {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!pattern.test(this.regForm.email)) {
            this.$emit('regFormCompletion', false)
            this.$toast.error('Ingrese un email valido')
          } else if (this.regForm.password === this.regForm.confirmPassword) {
            this.$emit('regFormCompletion', true)
            this.registerUser({
            name: this.userData.fullname,
            email: this.regForm.email,
            password: this.regForm.password,
            phone: this.userData.phone,
            doc_type_id: this.userData.documentType,
            number_doc: this.userData.documentNumber,
            country: 'Perú',
            birthday: '02/05/1998'
          })
            
          } else {
            this.$emit('regFormCompletion', false)
            this.$toast.error('Las contraseñas no coinciden')
          }
        } else {
          this.$emit('regFormCompletion', false)
          this.$toast.warning('Complete los campos antes de continuar')
        }
      } else if (this.currentStep === 3) {
        if (!this.paymentMethod.transfer && !this.paymentMethod.culqi) {
          this.$toast.warning('Seleccione un método de pago!');
        } else if (this.paymentMethod.transfer) {
          this.showTransfer = true;
        } else {
          this.culqiConfig.amount = this.products[0].precio.toFixed(2).replace(".", "");
          this.culqiConfig.description = this.products[0].nombre;
          var token
          var email
          await this.$culqi.openCheckout().then((response) => {
            token = response.id
            email = response.email
          }).catch(error => {
            this.errorMessage = error
            this.modalErrorPay = true;
          });

          let url = "https://api.culqi.com/v2/charges";
          let config = {
            headers: {
              "Content-type": "application/json",
              //Authorization: "Bearer sk_test_IgMIsYRwitu2jb5m",
              Authorization: "Bearer sk_live_ab08d50c685fcfdc",
            },
          };
          let body = {
            amount: this.culqiConfig.amount,
            currency_code: "PEN",
            email: email,
            source_id: token,
          };
          // const {data} = await this.$axios.post(url, body, config);
          this.overlay = true;
          await this.$axios.post(url, body, config).then((request) => {
            if (request.data.outcome.type == "venta_exitosa") {
              this.overlay = false;
              this.modalConfirmPay = true;
              const form = {
                user_id: localStorage.getItem("id_user"),
                product_id: this.products[0].id,
                amount: this.culqiConfig.amount,
                reference_code: request.data.reference_code,
                product_type: this.products[0].tipo_producto_id,
                is_offer: this.products[0].tipo_producto_id < 3 ? false : true,
              };
              this.$axios.post("/payments/save-payment", form);
              this.$emit("closePaymentModal");
            } else {
              this.modalErrorPay = true;
            }
          }).catch(error => {
            console.log(error);
            this.overlay = false;
            this.modalErrorPay = true;
          })
        }

      }

    },

    validate() {
      if (this.$refs.form.validate()) {
        this.sendTransferInfo();
        this.form = {};
        this.reset();
      } else {
        console.log("error");
      }
    },
    async registerUser(form) {
      try {
        const {
          status,
          data: response
        } = await this.$axios.post(
          "/public/registerAcademyUser",
          form
        );
        if (status === 200) {
          this.$emit('registrationSuccess', true)
          localStorage.setItem('userData', response.data)
          this.loginUser()
          this.$emit('nextStep', this.currentStep + 1)
          this.$toast.success('Registrado Correctamente')

        } else {
          this.$toast.error('Inténtelo nuevamente')
        }
      } catch (error) {
        if (error.response.data.message) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('Inténtelo nuevamente')
        }

      }



    },
    async loginUser() {
      try {
        const {
          status,
          data: response
        } = await this.$axios.post(
          "/public/auth/login",
          {
            email: this.regForm.email,
            password: this.regForm.password
          }
        );
        if (status === 200) {
          // console.log(response);
          const {
            data
          } = await response;
          // console.log(response);
          const {
            access_token,
            user
          } = await data;
          localStorage.setItem("id_user", user.id);
          localStorage.setItem("access_token", access_token);
          this.$axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          localStorage.setItem("name_user", user.name);
          localStorage.setItem("email_user", user.email);
        }
      } catch (error) {
        this.$toast.error(error)
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    async sendTransferInfo() {
      const form = {
        'user_id': localStorage.getItem("id_user"),
        'product_id': this.products[0].id,
        'reference_code': this.operationModel,
        'bank': this.bankModel,
      }
      await this.$axios.post("/payments/save-transfer-payment", form).then((response) => {
        if (response.data == "repeated") {
          this.repeated = true;
        } else if (response.data == "ok") {
          this.dialog = true;
        }
      });
    },
    transferSuccessDialog() {
      this.dialog = false;
      this.$emit("closePaymentModal");
    }
  },
  created() {
    // this.getCountries();
    // // this.isCourseBougth(this.$route.params.ide);
    // this.getAttributes();
    window.Vue.use(CulqiCheckout, this.culqiConfig);
  },
};
</script>

<style scoped>
.summary-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 1rem;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 800px;
  margin: 0 auto;
}

.summary-container hr {
  margin: 0px !important;
}

.product-cart-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  column-gap: 10px;
  width: 100%;

}

.product-item img {
  width: 80px;
  height: 80px;
}

.product-details {

  width: 100%;
  display: flex;
  flex-direction: column;
}

.price {
  font-weight: bold;
  align-self: flex-end;
}

.subtotal,
.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #f8bc02;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}
</style>
