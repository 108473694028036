<template>
  <div class="purchase-details">
    <form class="registration-form">
      <label for="cart-user-email">Correo:</label>
      <div class="input-group">
        <img src="@/assets/correo-electronico.png" alt="Correo electrónico" />
        <input id="cart-user-email" type="email" required v-model="registrationForm.email" />
      </div>
      <label for="cart-user-password">Contraseña:</label>
      <div class="input-group">
        <img src="@/assets/candado.png" alt="Contraseña" />
        <input id="cart-user-password" type="password" required v-model="registrationForm.password" />
      </div>
      <label for="cart-user-password-confirm">Confirmar contraseña:</label>
      <div class="input-group">
        <img src="@/assets/candado.png" alt="Confirmar contraseña" />
        <input id="cart-user-password-confirm" type="password" required v-model="registrationForm.confirmPassword" />
      </div>
      <!--   <button type="submit" class="btn-primary">Continuar</button> -->
    </form>
  </div>
</template>

<script>
export default {
  name: "RegisterDetails",
  props: {
    regForm:Object
  },
  data() {
    return {
      registrationForm:this.regForm
    };
  },
  methods: {
    onSubmit() {
      // Validar el formulario
      // Enviar datos al servidor
      // Redirigir a la siguiente vista
    },
  },
  watch:{
    registrationForm:{
      handler(newVal){
      this.$emit('regFormChange',newVal);
    },
    deep:true
    }
  }
};
</script>

<style scoped>
.purchase-details {
  padding: 20px;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  width: 80%;
}

.input-group img {
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.input-group input {
  width: 100%;
padding: 10px;
padding-left: 40px;
  font-size: 16px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 80%;
}
</style>
